import React from "react";
import { Helmet } from "react-helmet";

const SEO = () => {
  return (
    <Helmet>
      {/* Open Graph Metadata */}
      <meta property="og:title" content="Syncturma | AI-Powered Remote Workforce Management Software" />
      <meta
        property="og:description"
        content="Syncturma helps businesses manage their remote teams with AI-driven task allocation, real-time analytics, and advanced collaboration tools."
      />
      <meta property="og:url" content="https://syncturma.com" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://syncturma.com/logo.jpg" />

      {/* Twitter Metadata */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Syncturma | AI-Powered Remote Workforce Management Software" />
      <meta
        name="twitter:description"
        content="Optimize remote team collaboration and productivity with Syncturma, the AI-powered workforce management software."
      />
      <meta name="twitter:image" content="https://syncturma.com/logo.jpg" />

      {/* Structured Data for Organization */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Syncturma",
          "url": "https://syncturma.com",
          "logo": "https://syncturma.com/logo.jpg",
          "description":
            "Syncturma is a cutting-edge software solution for managing remote teams. Powered by AI, it streamlines task allocation, boosts productivity, and enhances team collaboration.",
          "sameAs": [
            "https://www.facebook.com/syncturma",
            "https://www.linkedin.com/company/syncturma",
            "https://twitter.com/syncturma"
          ],
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
