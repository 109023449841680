import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/pricing.css';
import { FaArrowLeft } from 'react-icons/fa'; // Import an icon for the back button


const Pricing = () => {
  const [isYearly, setIsYearly] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleToggle = () => {
    setIsYearly(!isYearly);
  };

  const navigate = useNavigate(); // Initialize navigate hook

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };
  
  const handleCardSelect = (cardName) => {
    setSelectedCard(cardName); // Update the selected card
  };

  return (
    <div className="pricing-container">
      <button className="back-button" onClick={handleBackClick}>
      <FaArrowLeft />
      </button>
      <h2>Plans & Pricing</h2>
      <div className="text-and-toggle">
      <p>
        Whether your team management needs are small or large, our< br />
        AI-powered solutions help you streamline your operations!
      </p>
      <div className="toggle-buttons">
        
        <button
          className={`toggle-btn ${!isYearly ? 'active' : ''}`}
          onClick={() => setIsYearly(false)}
        >
          MONTHLY
        </button>
        <button
          className={`toggle-btn ${isYearly ? 'active' : ''}`}
          onClick={() => setIsYearly(true)}
        >
          YEARLY
        </button>
      </div>
      </div>
      <div className="pricing-cards">
      <div
          className={`pricing-card ${
            selectedCard === 'Starter' ? 'selected' : ''
          }`}
          onClick={() => handleCardSelect('Starter')}
        >
          <h3>{isYearly ? '$240/year' : '$25/month'}</h3>
          {isYearly && <p className="savings">(Save $60!)</p>}
          <h4>Starter</h4>
          <h6>Unleash the power of automation.</h6>
          <ul>
            <li>Live Working Time</li>
            <li>Real-Time Screens</li>
            <li>Automatic Screenshots</li>
            <li>Monitor Mouse Click Activity</li>
            <li>Alerts for Missed Scheduled Work Times</li>
            <li>2 Team Members</li>
          </ul>
          <button className="choose-btn">Choose plan</button>
        </div>
        <div
          className={`pricing-card ${
            selectedCard === 'Professional' ? 'selected' : ''
          }`}
          onClick={() => handleCardSelect('Professional')}
        >
          <h3>{isYearly ? '$570/year' : '$59/month'}</h3>
          {isYearly && <p className="savings">(Save $128!)</p>}
          <h4>Professional</h4>
          <h6>Advanced tools to take your work to the next level.</h6>
          <ul>
            <li>Includes all Starter</li>
            <li>Internal Meeting</li>
            <li>Leave Management</li>
            <li>10 Team Members</li>
          </ul>
          <button className="choose-btn2">Choose plan</button>
        </div>
        <div
  className={`pricing-card most-popular ${
    selectedCard === 'Company' ? 'selected' : ''
  }`}
  onClick={() => handleCardSelect('Company')}
>
          <p className="badge">MOST POPULAR</p>
          <h3>{isYearly ? '$960/year' : '$99/month'}</h3>
          {isYearly && <p className="savings">(Save $228!)</p>}
          <h4>Company</h4>
          <h6 >Automation plus enterprise-grade features.</h6>
          <ul>
            <li>Includes all</li>
            <li>AI Task Allocation</li>
            <li>Unlimited Team</li>
            <li>Advanced Admin Tools</li>
            <li>Customizable Features</li>
          </ul>
          <button className="choose-btn3">Choose plan</button>
        </div>
      </div>
    </div>
  );
};

export default Pricing;