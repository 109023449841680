import React from "react";
import "../assets/styles/footer.css"; // CSS file for styling
import s1 from "../assets/images/s1.png";
import s2 from "../assets/images/s2.png";
import s3 from "../assets/images/s3.png";
import s4 from "../assets/images/s4.png";

function Footer() {
  return (
    <footer id="footer" className="footer-container" style={{ backgroundColor: "#06071B" }}>
      {/* Horizontal line at the top */}
      <hr style={{ border: "0.5px solid rgba(255, 255, 255, 0.1)", marginBottom: "5rem" }} />
<div className="footer-content">
  {/* Product Section */}
  <div className="footer-column">
    <h3>Product</h3>
    <ul>
      <li>Time Tracking Tools</li>
      <li>Real-Time Monitoring</li>
      <li>Automated Alerts</li>
      <li>Task Allocation with AI</li>
      <li>Employee Insights Dashboard</li>
    </ul>
  </div>

  {/* Information Section */}
  <div className="footer-column">
    <h3>Information</h3>
    <ul>
      <li>About Us</li>
      <li>Blog & Resources</li>
      <li>Help Center</li>
    </ul>
  </div>

  {/* Company Section */}
  <div className="footer-column">
    <h3>Company</h3>
    <ul>
      <li>Careers</li>
      <li>Partnerships</li>
      <li>News & Events</li>
      <li>Contact Us</li>
    </ul>
  </div>

  {/* Contact Us Section */}
  <div className="footer-column contact-column">
    <h3>Contact Us</h3>
    <div className="newsletter-signup">
      <input type="email" placeholder="Email address" />
      <button><img src={s4}  /></button>
    </div>
    <p>
      Add a newsletter signup option for users to stay informed about product
      updates or announcements.
    </p>
  </div>
</div>


      {/* Bottom Links */}
      <div className="footer-bottom">
        <div className="footer-legal">
          <span>Terms</span>
          <span>Privacy</span>
          <span>Cookies</span>
        </div>
        <div className="footer-social">
          <a href="https://www.linkedin.com/company/syncturma" className="social-icon"
          target="_blank" 
          rel="noopener noreferrer"
          >
            <img src={s1} alt="Social 1" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61569235614703&mibextid=LQQJ4d" className="social-icon"
          target="_blank" 
          rel="noopener noreferrer"
          >
            <img src={s2} alt="Social 2" />
          </a>
        </div>
      </div>
     
      <div className="copyright">
        <p>© 2024 Syncturma. All Rights Reserved.</p>
      </div> <hr/>
    </footer>
  );
}

export default Footer;
