import React, { useEffect, useState, useRef } from "react";
import "../assets/styles/services.css";
import g1 from "../assets/images/g1.svg";
import g2 from "../assets/images/g2.png";
import g3 from "../assets/images/g3.png";
import g4 from "../assets/images/g4.png";
import AOS from "aos"; // Import AOS for scroll animations
import "aos/dist/aos.css"; // Import AOS CSS for styles

function Services() {
  const services = [
    {
      title: "Consultation and Implementation",
      description:
        "Our experts work with you to integrate our solution seamlessly into your organization.",
      image: g1,
    },
    {
      title: "Technical Support and Training",
      description:
        "Comprehensive training and support ensure your team fully benefits from every tool and capability.",
      image: g2,
    },
    {
      title: "Performance Optimization",
      description:
        "Leverage AI-driven insights and analytics to continuously optimize team performance and productivity.",
      image: g3,
    },
    {
      title: "Customization Options",
      description:
        "Our experts work with you to tailor solutions seamlessly into your organization.",
      image: g4,
    },
  ];

  const [translateX, setTranslateX] = useState(0);
  const [itemsPerView, setItemsPerView] = useState(4); // Default: Show 4 items at once
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTouching, setIsTouching] = useState(false); // State to track if the slider is being touched

  const touchStartX = useRef(0); // To track the starting X position of the touch
  const touchEndX = useRef(0); // To track the ending X position of the touch
  const autoScrollIntervalRef = useRef(null); // To store the auto scroll interval

  const startAutoScroll = () => {
    autoScrollIntervalRef.current = setInterval(() => {
      setTranslateX((prev) => {
        const maxTranslate = (services.length * 100) / itemsPerView; // Adjust max translation based on itemsPerView
        return prev >= maxTranslate ? 0 : prev + (100 / itemsPerView); // Adjust the translation step for smoother looping
      });
      setCurrentIndex((prevIndex) => {
        const maxIndex = services.length - 1;
        return prevIndex >= maxIndex ? 0 : prevIndex + 1;
      });
    }, 5000); // Automatically scroll every 5 seconds
  };

  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1200, // Duration for animations
      once: true, // Animation happens only once
    });

    // Handle screen resize
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setItemsPerView(1); // Show 1 item at a time on small screens
      } else if (window.innerWidth <= 768) {
        setItemsPerView(2); // Show 2 items at a time on medium screens
      } else if (window.innerWidth <= 1024) {
        setItemsPerView(3); // Show 3 items at a time on larger screens
      } else {
        setItemsPerView(4); // Show 4 items at a time on very large screens
      }
    };

    handleResize(); // Initial call
    window.addEventListener("resize", handleResize); // Add event listener for window resize

    // Start the auto scroll initially
    startAutoScroll();

    // Cleanup interval on component unmount
    return () => {
      clearInterval(autoScrollIntervalRef.current);
      window.removeEventListener("resize", handleResize);
    };
  }, [itemsPerView, services.length]); // Dependency on itemsPerView and services.length to ensure correct resizing

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    setTranslateX(index * 100); // Update translate value for the selected slide
  };

  // Handle touch start to get the starting position and disable auto scrolling
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
    setIsTouching(true); // Start touching
    clearInterval(autoScrollIntervalRef.current); // Disable auto-scroll when touching
  };

  // Handle touch move to track swipe direction
  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  // Handle touch end to detect swipe and change active card, and resume auto-scrolling after a few seconds
  const handleTouchEnd = () => {
    const swipeDistance = touchStartX.current - touchEndX.current;
    if (Math.abs(swipeDistance) > 50) {
      if (swipeDistance > 0) {
        // Swipe Left
        setCurrentIndex((prevIndex) => {
          const nextIndex = (prevIndex + 1) % services.length; // Loop back to first item
          setTranslateX(nextIndex * 100); // Scroll to the new index
          return nextIndex;
        });
      } else {
        // Swipe Right
        setCurrentIndex((prevIndex) => {
          const newIndex = (prevIndex - 1 + services.length) % services.length; // Loop back to last item
          setTranslateX(newIndex * 100); // Scroll to the new index
          return newIndex;
        });
      }
    }

    // Resume auto-scrolling after a small delay (e.g., 2 seconds)
    setTimeout(() => {
      setIsTouching(false); // Stop touching
      startAutoScroll(); // Start auto-scroll again after 2 seconds
    }, 2000);
  };

  return (
    <section id="services" className="services-container">
      <div className="services-header" data-aos="fade-up">
        <h2 data-aos="fade-up" data-aos-delay="100">Services</h2>
        <p data-aos="fade-up" data-aos-delay="200">
          We Provide A Range Of Services Designed To Enhance <br />
          Your Remote Workforce Management
        </p>
      </div>
      <div className="slider-wrapper">
        <div className="ash-box">
          <div
            className="services-slider"
            style={{
              transform: `translateX(-${translateX}%)`, // Adjust the translation based on state
            }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {services.concat(services).map((service, index) => (
              <div
                className="service-box"
                key={index}
                data-aos="zoom-in"
                data-aos-delay={`${index * 200}`} // Apply delay for each service box
              >
                <img src={service.image} alt={service.title} />
                <h3>{service.title}</h3>
                <p>
                  {service.description.split(" ").map((word, idx) => (
                    <React.Fragment key={idx}>
                      {word}{" "}
                      {idx === Math.ceil(service.description.split(" ").length / 2) ? (
                        <span className="mobile-only-break">
                          <br />
                        </span>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  ))}
                </p>
              </div>
            ))}
          </div>
        </div>
        {/* Dots for navigation - only visible for mobile */}
        {itemsPerView === 1 && (
          <div className="slider-dots">
            {services.map((_, index) => (
              <div
                key={index}
                className={`slider-dot ${currentIndex === index ? "active" : ""}`}
                onClick={() => handleDotClick(index)}
              ></div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default Services;
