import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import Header from "./components/header";
import HeroSection from "./components/hero";
import AboutSection from "./components/about";
import NewsSection from "./components/news";
import Features from "./components/features";
import Services from "./components/services";
import Footer from "./components/footer";
import "animate.css";
import Snowfall from "react-snowfall"; // Import Snowfall
import {  Routes, Route, useLocation } from 'react-router-dom';
import Pricing from './components/pricing'; // Import the Pricing component
import SEO from "./components/SEO"


function App() {
  const [activeLink, setActiveLink] = useState("home");
  const [snowflakeCount, setSnowflakeCount] = useState(100);

  const location = useLocation(); // Get the current location (path)

  useEffect(() => {
    const updateSnowflakeCount = () => {
      if (window.innerWidth < 768) {
        setSnowflakeCount(50); // Fewer flakes for mobile
      } else {
        setSnowflakeCount(100);
      }
    };
  
    updateSnowflakeCount();
    window.addEventListener("resize", updateSnowflakeCount);
    return () => window.removeEventListener("resize", updateSnowflakeCount);
  }, []);

  // Hero Section (Home)
  const { ref: homeRef, inView: homeInView } = useInView({
    threshold: 0.1, // Trigger when at least 10% of Home is visible
    onChange: (inView) => {
      if (inView) setActiveLink("home");
    },
  });

  // About Section
  const { ref: aboutRef, inView: aboutInView } = useInView({
    threshold: 0.5, // Trigger when at least 50% of About is visible
    onChange: (inView) => {
      if (inView) setActiveLink("about");
    },
  });

  // Features Section
  const { ref: featuresRef, inView: featuresInView } = useInView({
    threshold: 0.5,
    onChange: (inView) => {
      if (inView) setActiveLink("features");
    },
  });

  // News Section
  const { ref: newsRef, inView: newsInView } = useInView({
    threshold: 0.5,
    onChange: (inView) => {
      if (inView) setActiveLink("news");
    },
  });

  // Services Section
  const { ref: servicesRef, inView: servicesInView } = useInView({
    threshold: 0.5,
    onChange: (inView) => {
      if (inView) setActiveLink("services");
    },
  });

  // Footer Section
  const { ref: footerRef, inView: footerInView } = useInView({
    threshold: 0.5,
    onChange: (inView) => {
      if (inView) setActiveLink("footer");
    },
  });

  // Detect when the user scrolls to the very top of the page
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setActiveLink("home");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (

    <div>
       {/* Add Snowfall Effect */}
       <Snowfall
        color="white"
        snowflakeCount={100} // Number of snowflakes
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          zIndex: 9999,
          pointerEvents: "none", // To ensure the snowfall doesn’t block interactions
        }}
      />

<SEO />
{/* Conditionally render the Header only on the home route */}
{location.pathname === "/" && <Header activeLink={activeLink} setActiveLink={setActiveLink} />}

     {/* Define Routes for the landing page and pricing page */}
     <Routes>
        {/* Landing Page Route */}
        <Route
          path="/"
          element={
            <div>
              <section ref={homeRef} id="home">
                <HeroSection />
              </section>
              <section ref={aboutRef} id="about">
                <AboutSection />
              </section>
              <section ref={featuresRef} id="features">
                <Features />
              </section>
              <section ref={newsRef} id="news">
                <NewsSection />
              </section>
              <section ref={servicesRef} id="services">
                <Services />
              </section>
              <section ref={footerRef} id="footer">
                <Footer />
              </section>
            </div>
          }
        />

        {/* Pricing Page Route */}
        <Route path="/pricing" element={<Pricing />} />
      </Routes>
    </div>
  );
}

export default App;
