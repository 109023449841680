import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { FaGlobe } from 'react-icons/fa';
import Logo from '../assets/images/Syncturma.png';
import '../assets/styles/header.css';
import { useNavigate } from 'react-router-dom';




const Header = ({ activeLink, setActiveLink }) => {
  const navigate = useNavigate(); // Initialize navigate

  const handleNavigation = () => {
    // Check if we are already on the Pricing page
    if (window.location.pathname !== "/pricing") {
      navigate("/pricing"); // Navigate to the pricing page
    }
  };
  

  return (
    <header>
      <Navbar
        expand="lg"
        sticky="top"
        style={{
          background: '#06071B',
          boxShadow: '0px -1px 1px 0px #0000001A inset',
          position: 'relative',
          height: 'auto',
        }}
      >
        <Container>
          {/* Navbar content with logo and nav links close together */}
          <Navbar.Brand href="#" style={{ marginRight: '15%' }}>
            <img
              src={Logo}
              alt="SyncTurna Logo"
              style={{ width: '100px', height: 'auto' }}
            />
          </Navbar.Brand>

          {/* Navbar Toggle Button with "MENU" text */}
          <span className="menu-text">MENU</span> {/* Add MENU text */}
          <Navbar.Toggle 
            aria-controls="navbar-nav" 
            style={{ backgroundColor: 'transparent' }}  
            className="custom-toggler"
          />
          

          {/* Navbar Collapse Section */}
          <Navbar.Collapse id="navbar-nav">
            <Nav className="d-flex justify-content-start ">
              {["home", "about", "features", "news", "services"].map((link) => (
               <Nav.Link
               key={link}
               href={`#${link}`}
               className={`nav-link ${activeLink === link ? "active-link" : ""}`}
               onClick={() => setActiveLink(link)} // Set active link on click
               style={{
                 color: activeLink === link ? "#FF7000" : "#ACACAC", // Highlight the active link
                 fontWeight: activeLink === link ? 700 : 500,
               }}
             >
               {link.charAt(0).toUpperCase() + link.slice(1)}
             </Nav.Link>
             
              ))}
            </Nav>

            {/* Additional Nav Links for "English" and "Contact Us" on the left */}
            <Nav className="ms-auto d-flex ">
            <div
                onClick={handleNavigation}
                style={{
                  cursor: "pointer", // Indicate it's clickable
                  textDecoration: "none", // Remove underline
                  color: "#ACACAC", // Default text color
                  fontFamily: "Inter, sans-serif", // Font Inter
                  fontWeight: 500, // Medium weight
                  display: "flex",
                  alignItems: "center",
                  marginTop: "0",
                }}
                className="nav-link-plans"
                onMouseDown={(e) => (e.target.style.color = "#FF7000")} // Change color on click
                onMouseUp={(e) => (e.target.style.color = "#ACACAC")} // Reset color after click
              >
                Plans & Pricing
              </div>
              <Nav.Link
                href="#english"
                style={{
                  color: '#FFFFFF',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FaGlobe style={{ marginRight: '5px' }} /> EN
              </Nav.Link>
              

              {/* "Contact Us" Button */}
              <Button
                href="#footer"
                variant="outline-light"
                className={`contact-us-btn ${
                  activeLink === "footer" ? "active-link" : ""
                }`}
                style={{
                  height: '40px',
                  backgroundColor:
                    activeLink === "footer" ? "#FF7000" : 'transparent',
                  borderColor: activeLink === "footer" ? "#FF7000" : "#FFFFFF",
                  color: activeLink === "footer" ? "#FFFFFF" : "#FFFFFF",
                }}
              >
                Contact Us
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
